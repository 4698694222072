.vm-MenuList {
  margin: 0 0 10rem 0;

  .vm-MenuList-title {
    padding: 15px 20px 5px;
    color: #fff;
    font-weight: 800;
  }

  .vm-MenuList-item {
    a {
      display: flex;
      padding: 4px 15px;
      color: #d8d8d8;
      font-weight: 500;
      font-size: 14px;

      &:hover {
        color: #fff;
        background-color: #333;
      }
    }

    div {
      display: flex;
      padding: 4px 15px;
      color: #d8d8d8;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #333;
      }
    }

    &-icon {
      svg {
        font-size: 18px;
      }
    }

    &-literal {
      margin-left: 10px;
    }

    &--isActive {
      background-color: #333;
      border-left: 3px solid #fff;

      a {
        padding-left: 17px;
        color: #fff;
      }
    }
  }
}

.MuiPaper-elevation1 {
  background-color: inherit !important;
  box-shadow: none !important;
}
