@import '~bulma/sass/utilities/initial-variables.sass';
@import './menu-list/index.scss';

$nav-height: 52px;

$menu-width: 15rem;
$menu-width-dk: 220px;

.vm-Menu {
  flex-direction: column;
  flex-shrink: 0;
  width: 0;
  height: calc(100vh - #{$nav-height});
  padding-top: 5px;
  overflow-y: auto;
  color: #fff;
  background-color: #2e3e4e;

  &--isOpen {
    width: $menu-width;

    @media screen and (min-width: $tablet) {
      width: $menu-width-dk;
    }
  }
}
