.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #ecf0f1;

  & a {
    color: #00d1b2;
    font-weight: 600;
  }
}

.login {
  margin: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;

  & p {
    margin-bottom: 2rem;
  }

  & div {
    margin-bottom: 1.5rem;
  }

  & button {
    width: 100%;
  }
}
