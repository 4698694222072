.vm-Selectable {
  .react-autosuggest__container {
    position: relative;
    margin-top: 8px;
  }

  .react-autosuggest__input {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    height: 55.95px;
    padding: calc(0.5em - 1px) calc(0.75em - 1px);
    color: #363636;
    font-size: 1rem;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.5;
    vertical-align: top;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    -moz-appearance: none;
    -webkit-appearance: none;

    &--focused {
      outline: 0;
    }

    &--open {
      border-radius: 4px 4px 0 0;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .react-autosuggest__suggestions-container {
    &--open {
      position: absolute;
      top: 35px;
      z-index: 10;
      width: auto;
      font-weight: 300;
      font-size: 15px;
      background-color: #fff;
      border: 1px solid #dbdbdb;
      border-radius: 0 0 4px 4px;
    }
  }

  .react-autosuggest__suggestion {
    padding: 6px 10px;
    cursor: pointer;

    &--highlighted {
      background-color: #ddd;
    }
  }

  .label {
    margin-bottom: 6px;
  }
}
