@import '~bulma/sass/utilities/initial-variables.sass';

.vm-Tile {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: stretch;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;

  @media screen and (min-width: $tablet) {
    display: flex;
  }

  &-left {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: stretch;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
    padding-bottom: 1rem;

    @media screen and (min-width: $tablet) {
      flex: none;
      min-width: 50%;
      padding-right: 0.75rem;
      padding-bottom: 0;
    }
    &-35 {
      @media screen and (min-width: $tablet) {
        width: 35%;
      }
    }
  }

  &-right {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: stretch;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;

    @media screen and (min-width: $tablet) {
      padding-left: 0.75rem;
    }
  }
}
