@import '../../../components/title/index.scss';
@import '../../../components/detailActions/index.scss';
@import '../../../components/tile/index.scss';
.progress_bar {
  min-width: 1rem;
  max-width: 4.5rem;
}
.row_with {
  min-width: 10rem;
}

.vm-RoutesTodayDatatable {
  &-progress {
    display: flex;
    align-items: center;
    padding-top: 2px;

    &Counter {
      flex-basis: 80px;
    }
  }

  &-date {
    white-space: nowrap;
  }
  &.is-order-asc::after {
    float: right;
    content: '\2193';
  }
  &.is-order-desc::after {
    float: right;
    content: '\2191';
  }
  &.is-order-norm::after {
    float: right;
    content: '\2191''\2193';
  }
}
