@import '../../../components/detailActions/index.scss';
@import '../../../components/tabs/index.scss';

.vm-ClientsDetail {
  .vm-Datatable {
    th:first-child {
      width: 90%;
    }
  }
}

.aliasform {
  margin: 1.5rem;
}
