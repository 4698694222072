@import '~bulma/bulma';

$footer-height: 56px;

.vm-MainContent {
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.vm-Container {
  width: 100%;
}

.vm-Wrapper {
  height: calc(100vh - #{$footer-height});
  overflow-y: auto;

  &-content {
    padding: 15px;
  }
}
