#root {
  width: 100%;
}

html {
  height: 100%;
}

body {
  display: flex;
  height: 100%;
  margin: 0px;
  overflow: hidden;
  font-size: $fontSize;
  font-family: $fontFamily;
}

a {
  color: inherit;
}
