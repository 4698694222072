@import '../../components/filters/index.scss';
@import '../../components/toggle/index.scss';
@import '../../components/modal/index.scss';

.vm-IncentivesDetailModal-list {
  ul {
    margin-left: 2em;
    list-style: disc outside;
  }

  & + .vm-IncentivesDetailModal-list {
    margin-top: 16px;
  }
}
