@import 'grid';
@import 'theme';
@import 'base';
@import '../components/menu/index.scss';
@import '../components/nav/index.scss';
@import '../pages/clients/detail/index.scss';
@import '../pages/clients/new/index.scss';
@import '../pages/incentives/index.scss';
@import '../pages/machines/index.scss';
@import '../pages/distribution_routes/today/index.scss';
@import '../components/selectableRedux/index.scss';

.MuiTableSortLabel-icon {
  color: white !important;
}

.vm-Filters {
  margin: 1rem 0;
}

.margin_left {
  margin-left: 2rem;
}

.margin_top {
  margin-top: 2rem;
}

.margin_bottom {
  margin-bottom: 2rem !important;
}

.margin_right {
  margin-right: 2rem;
}

#container {
  height: 100%;
  display: flex;
}

#sidebar {
  flex-basis: 15rem;
  flex-grow: 1;
  padding: 1rem;
  max-width: 30rem;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

#map {
  flex-basis: 0;
  flex-grow: 4;
  height: 100%;
}