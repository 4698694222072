.vm-Modal {
  .modal-content {
    height: 80vw;
    background-color: #fff;
    border-radius: 4px;
  }

  &-title {
    padding: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    background-color: #2e3e4e;
  }

  &-content {
    padding: 15px;
  }
}
