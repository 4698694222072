.LoadingStyles {
  margin: 30px 0;
  text-align: center;
}

.LoadingStylesCenter {
  text-align: center;
}

.LdsDualRing {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.LdsDualRing:after {
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border: 5px solid #2e3e4e;
  border-color: #2e3e4e transparent #2e3e4e transparent;
  border-radius: 50%;
  animation: LdsDualRing 1.2s linear infinite;
  content: ' ';
}

@keyframes LdsDualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
