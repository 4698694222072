@import '~bulma/sass/utilities/initial-variables.sass';

.vm-Nav {
  display: flex;
  background-color: #2e3e4e;

  a,
  a:hover {
    color: #fff;
  }

  &-burger {
    margin-left: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }

    @media screen and (min-width: $desktop) {
      display: block;
    }
  }

  &-logo {
    &-client {
      margin-left: 10px;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.navbar-burger {
  color: #fff !important;
}
