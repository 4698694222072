.vm-DetailActions {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  .icon {
    margin-right: 0;
  }
}

.articles_filter {
  display: flex;
  justify-content: space-between;
}

.weekend {
  background-color: rgb(253 136 136) !important;
}

.print_button {
  margin-top: 1rem;
}

.currentDay {
  background-color: #d3fad9 !important;
}

.actual-route-title {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
}
