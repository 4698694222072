.vm-Title {
  margin-bottom: 1.5rem;

  .title {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  &-explanation {
    color: #666;
  }
}
